<template>
<div class="custom-time-picker">
    <span class="custom-label pl-9">
        Czas
    </span>

    <div class="v-input__prepend-outer">
        <div class="v-input__icon v-input__icon--prepend"><i aria-hidden="true" class="v-icon notranslate mdi mdi-clock-outline theme--light"></i>
        </div>
    </div>

    <vue-timepicker
        v-model="time"
        :rules=rules
        hide-clear-button
        :disabled="disabled"
    >
    </vue-timepicker>
</div>
</template>

<script>

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'time-picker',
  props: {
    rules: null,
    value: null,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueTimepicker
  },
  computed: {
    time: {
      get () {
        if (this.value) {
          const val = this.value.split('T')[1]
          const hours = val.split(':')[0]
          const rest = val.split(':')[1]
          const minutes = rest.length > 2 ? rest.substring(0, 2) : rest

          return {
            HH: hours,
            mm: minutes
          }
        }

        return {
          HH: null,
          mm: null
        }
      },
      set (value) {
        var date = this.value?.split('T')[0] ?? '2022-11-29'
        if (value) { this.$emit('input', date + 'T' + (value.HH ?? '00') + ':' + (value.mm ?? '00') + ':00') }
      }
    }
  }
}
</script>

<style>
 input.display-time {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #949494 !important;
}
.custom-time-picker span.custom-label {
    font-size: 12px !important;
    padding-bottom: 15px !important;
}
.custom-time-picker {
    position: relative;
    top: -14.5px;
}
.custom-time-picker div.v-input__prepend-outer{
    position: relative;
    top: 4px;
}
</style>
